.short-overview {
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    margin-bottom: 120px;

    &__tech {
        margin-bottom: 24px;
        img {
            margin-right: 1.5rem;
            margin-bottom: 1rem;
            max-height: 32px;
            vertical-align: middle;
        }
    }

    &__description {
        margin-bottom: 40px;
    }

    &__img {
        width: 86%;
        margin: auto;
        display: block;
        @media (min-width: 768px) and (max-width: 989px) {
            width: 56%;
        }
    }
    @media (min-width: 990px) {
        flex-direction: row;
        margin-bottom: 100px;

        &_description {
            margin-bottom: 60px;
        }
    }
}
