.btn {
    outline:none;
    border:none;
    padding: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    position: relative;
    &:hover, &:active, &:focus, &:visited {
        outline:none;
    }
    
    &--primary {
        font-size: 1.1em;
        width: 100%;
        height: 50px;
        background: var(--c-red);
        color: var(--c-white);
        box-shadow: var(--shadow-red);
        transition: .15s ease-out;
        &:hover {
            transform: translateY(3px);
            box-shadow: var(--shadow-red-hover);
        }
    }

    &--small {
        width: 150px;
        height: 35px;
        font-size: 1em;
    }

    &--read-more {
        color: var(--c-red);
        background: none;
        font-size: 1.02em;
        line-height: 1em;
        margin-top: 15px;
        -moz-transform: translateX(10px);
        transform: translateX(10px);
        transition: .28s cubic-bezier(0.35, 0.42, 0.58, 1);
        white-space: nowrap;
        display: inline-block;
        text-decoration: none;
        &:before {
            content:'';
            position: absolute;
            width: 10px;
            height: 10px;
            background: var(--c-red);
            bottom: -10px;
            left: -10px;
            transition: inherit;
        }
    }
    @media (min-width: 768px) {
        cursor:pointer;
    }
    @media (min-width: 990px) {
        &--primary {
            height: 60px;
            font-size: 1.2em;
        }
        &--small {
            height: 40px;
            font-size: 1.1em;
        }
    
        &--read-more {
            &:hover {
                -moz-transform: translateX(0);
                transform: translateX(0);
                &:before {
                    left: 100%;
                }
            }
        }
    }
}