/* ============================================ */
/*                   Footer                     */
/* ============================================ */

.footer {
    padding: 40px 0;
    & > .content-block {
        justify-content: center;
    }

    @media (min-width: 990px) {
        padding: 0;
        position: fixed;
        right: 30px;
        bottom: 50px;
        display: block;
        z-index: 1;
        & > .content-block {
            justify-content: center;
            display: block;
            margin-top: 20px;
            max-width: 100%;
        }
    }
}

.social-link {
    height: 25px;
    opacity: .3;
    @media (min-width: 990px) {
        transition: .12s ease-out;    
        &:hover {
            opacity: .5;
        }
    }
}

.floating-nav {
    display: none;

    @media (min-width: 990px) {
        display: flex;
        position: fixed;
        right: 0;
        bottom: 50%;
        width: 50px;
        height: 320px;
        margin-bottom: -160px;
        z-index: 2;
        align-items: center;
        justify-content: flex-start;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        li {
            writing-mode: vertical-rl;
            height: 22px;
            color:var(--c-gray);
            cursor: pointer;
            line-height: 1em;
            &:not(:last-child) {
                margin-bottom: 20px;
            }

            a {
                opacity: .3;
                transition: .12s ease-out;
                &:hover {
                    opacity: .5;
                }
            }
        }
        .floating-nav__item-active {
            opacity: 1;
            color:var(--c-red);
            &:hover {
                opacity: 1;
                color:var(--c-red);
            }
        }

        & + .floating-nav-btn {
            bottom: 25%;
        }
    }
}

/**** Floating Button (Get in Touch) ****/
.floating-nav-btn {
    display: flex;
    position: fixed;
    right: -30px;
    bottom: 15px;
    width: 50px;
    height: 130px;
    margin-bottom: 0;
    z-index: 2;
    align-items: flex-end;
    justify-content: flex-start;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    li {
        writing-mode: vertical-rl;
        height: auto;
        color:var(--c-gray);
        cursor: pointer;
        line-height: 1em;
        
        a {
            opacity: 0.85;
            transition: .12s ease-out;
            color: var(--c-red);
            font-weight: 700;
            &:hover {
                opacity: 1;
            }
        }
    }
    @media (min-width: 768px) and (max-width: 989px) {
        right: -15px;
    }
    @media (min-width: 990px) {
        right: 0;
        bottom: 50%;
        margin-bottom: -65px;
        align-items: center;
        @supports (-ms-ime-align:auto) {
            height: 120px;
            margin-bottom: -60px;
            display: block;
        }
    }
}