/* ============================================ */
/*                How We Do                     */
/* ============================================ */

.list-block {
    position: relative;
    h2 {
        margin-bottom: 40px;
    }

    &__item {
        margin-bottom: 50px;
        position: relative;

        &_number {
            font-family: "Montserrat", sans-serif;
            font-weight: 700;
            font-size: 6em;
            line-height: 0.8;
            color:var(--c-lightgray);
            opacity: 0.5;
            writing-mode: vertical-rl;
            text-orientation: mixed;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
        &_content {
            padding: 40px 0;
        }
        &_background {
            display: none;
            position: absolute;
            width: calc(100% + var(--padding-size) * 2);
            height: 330px;
            top: 130px;
            left: calc(-1 * var(--padding-size));
            box-shadow: var( --shadow-black);
            background: var( --c-whitesmoke);
        }

        h3 {
            font-family: "Montserrat",sans-serif;
            font-weight: 700;
            font-size: 2em;
            color:var(--c-red);
            margin-top: 0;
            margin-bottom: 35px;
        }
        p {
            margin: 0;
        }
    }

    @media (min-width: 990px) {
        margin-bottom: 80px;
        h2 {
            margin-bottom: 90px;
        }

        &__item {
            &_number {
                top:40px;
            }
            &_content {
                margin-left: 25%;
            }
            &_background {
                max-width: calc((100% / var(--cols-count)) * 3 + var(--padding-size) * 2);
                left: calc((100% / var(--cols-count)) * 1 - var(--padding-size));
                height: 256px;
                top:205px;
            }
            h3 {
                font-size: 2.4em;
            }
        }
    }
}

.text {
    font-size: 1.02em;
    line-height: 1.5em;
    @media (min-width: 990px) {
        font-size: 1.12em;
        line-height: 1.6em;
    }    
}