/* ============================================ */
/*                    Article                    */
/* ============================================ */
.blog__article {
    &_content {
        margin-bottom: 100px;
        img {
            display: block;
            max-width: 100%;
            margin: 55px auto;
        }
        a {
            color: var(--c-red);
        }
        p {
            margin-bottom: 40px;
        }
        h2 {
            font-size: 1.4em;
            font-weight: 600;
            line-height: 1.5em;
        }
    }
    &_title{
        color: var(--c-black);
    }
}