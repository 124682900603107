
/* ============================================ */
/*                  Contact                     */
/* ============================================ */

.contact__item {
    margin-bottom: 40px;

    &_location {
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        font-size: 1.1em;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0 0 20px 0;
        
        &--active {
            &:before {
                content: '';
                width: 10px;
                height: 10px;
                background: var(--c-red);
                display: block;
                margin-right: 10px;
            }
        }
    }

    &_details {
        margin-bottom: 7px;

        & > a,
        & > a:active,
        & > a:focus,
        & > a:visited {
            color:var(--c-gray);
            text-decoration: none;
            font-size: 1.1em;
        }

        & > a:hover {
            color:var(--c-red);
        }
    }
}


.contact__map {
    background: lightgrey;
    width: 100%;
    overflow: hidden;
    &:after {
        content: '';
        display: block;
        position: relative;
        padding-bottom: 100%;
    }
}