/* ============================================ */
/*                     News                     */
/* ============================================ */

.news__item {
    margin-bottom: 40px;
    
    &_date {
        color:var(--c-red);
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        margin-bottom: 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &:before {
            content: '';
            width: 10px;
            height: 10px;
            background: var(--c-red);
            display: block;
            margin-right: 10px;
        }
    }

    &_text {
        margin:0;

        &__link, 
        &__link:hover,
        &__link:active,
        &__link:focus,
        &__link:visited {
            color:var(--c-red);
            text-decoration: underline;
        }
    }

    &_img {
        width: 100%;
        margin-bottom: 15px;
    }
}


/*** Variations of order img and text ***/

.news__item {
    &_date + .news__item_img {
        margin-top: 5px;
    }
    &_text + .news__item_img {
        margin-top: 20px;
        margin-bottom: 0;
    }
    &_text + .news__item_img + .news__item_text {
        margin-top: 15px;
    }
}