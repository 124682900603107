.section-title {
    h1 {
        color: var(--c-black);
        margin:60px 0 140px;
    }
    @media (min-width: 768px) {
        h1 {
            margin:60px 0 100px;
        }
    }
    @media (min-width: 990px) {
        min-height: 650px;
        &-smaller {
            min-height: 365px;
        }
        h1 {
            font-size: 5.5em;
        }
    }
}