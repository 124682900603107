/* ============================================ */
/*                    Blog                      */
/* ============================================ */

.blog {
    margin-bottom: 80px;
    &__item {
        margin-bottom: 5rem;
        color: var(--c-gray);
        text-decoration: none;
        
        &_img {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            & > img {
                transition: .28s cubic-bezier(0.35, 0.42, 0.58, 1);
                width: 100%;
            }
            &:hover {
                & > img {
                    transform:scale(1.5)
                }
            }
        }

        &_title {
            font-family: "Montserrat", sans-serif;
            font-weight: 600;
            font-size: 1.1em;
            line-height: 1.5em;
            margin: 25px 0 15px 0;
        }

        &_text {
            margin: 0;
        }
    }

    @media (min-width: 990px) {
        &__item {
            display: flex;
            align-items: flex-start;

            & > * {
                flex:1;
            }

            &_title {
                margin: 0 0 15px 0;
                font-size: 1.4em;
                padding: 0 40px;
            }
            &_text {
                padding: 0 40px;
            }
            &_img {
                max-width: calc((100% / 4) * 1.5);
            }
            &_btn-area {
                margin-left: calc((100% / 2.5) * 0.5);
            }
        }
    }
}