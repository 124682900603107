/* ============================================ */
/*                 Who We Are                   */
/* ============================================ */

.who-we-are {
    margin-bottom: 70px;
    .grid {
        width: 100%;
        display: grid;
        grid-template-rows: repeat(6, 1fr);
        grid-template-columns: repeat(2, 1fr);
        @media (min-width: 768px) {
            grid-template-rows: repeat(5, 1fr);
            grid-template-columns: repeat(4, 1fr);
        }
        @media (min-width: 990px) {
            grid-template-rows: repeat(4, 1fr);
            grid-template-columns: repeat(6, 1fr);
            margin-top: -16%;
        }
    }
    @media (min-width: 990px) {
        min-height: 100vh;
    }
}

.square {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    &_content {
        font-size: 1.05em;
        & > b {
            font-family: "Montserrat", sans-serif;
            font-weight: 700;
            font-size: 3em;
            display: block;
            line-height: 1em;
        }
    }

    &--lightgray {
        background: var(--c-whitesmoke);
        box-shadow: var(--shadow-black);
        z-index: 1;
    }
    &--red {
        background: var(--c-red);
        color:var(--c-white);
    }
    &--gray {
        background: #e1e1e1;
    }

    &__floating {
        display: none;
    }

    @media (min-width: 990px) {
        &_content {
            font-size: 1.4em;
            & > b {
                font-size: 3.2em;
            }
        }

        &__floating {
            display: block;
        }
    }
}

.gr {
    &-xs {
        &-1 {
            grid-row: 1
        }
        &-2 {
            grid-row: 2
        }
        &-3 {
            grid-row: 3
        }
        &-4 {
            grid-row: 4
        }
        &-5 {
            grid-row: 5
        }
        &-6 {
            grid-row: 6
        }
        &-2-4 {
            grid-row: 2 / 4;
        }
    }
    @media (min-width: 768px) {
        &-md {
            &-1 {
                grid-row: 1
            }
            &-2 {
                grid-row: 2
            }
            &-3 {
                grid-row: 3
            }
            &-4 {
                grid-row: 4
            }
            &-5 {
                grid-row: 5
            }
            &-md-2-4 {
                grid-row: 2 / 4;
            }
        }
    }
    @media (min-width: 990px) {
        &-lg {
            &-1 {
                grid-row: 1
            }
            &-2 {
                grid-row: 2
            }
            &-3 {
                grid-row: 3
            }
            &-4 {
                grid-row: 4
            }
            &-3-5 {
                grid-row: 3 / 5;
            }
        }
    }
}

.gc {
    &-xs {
        &-1 {
            grid-column: 1;
        }
        &-2 {
            grid-column: 2;
        }
        &-1-3 {
            grid-column: 1 / 3;
        }
    }
    @media (min-width: 768px) {
        &-md {
            &-1 {
                grid-column: 1;
            }
            &-2 {
                grid-column: 2;
            }
            &-3 {
                grid-column: 3;
            }
            &-4 {
                grid-column: 4;
            }
            &-2-4 {
                grid-column: 2 / 4;
            }
        }
    }
    @media (min-width: 990px) {
        &-lg {
            &-1 {
                grid-column: 1;
            }
            &-2 {
                grid-column: 2;
            }
            &-3 {
                grid-column: 3;
            }
            &-4 {
                grid-column: 4;
            }
            &-5 {
                grid-column: 5;
            }
            &-6 {
                grid-column: 6;
            }
            &-2-4 {
                grid-column: 2 / 4;
            }
        }
    }
}