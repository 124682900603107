/* ============================================ */
/*              404 Error Page                  */
/* ============================================ */

.p404 {
    min-height: 100vh;

    &__title {
        margin: 90px 0 30px;
        font-size: 6em;
    }
    &__subtitle {
        margin: 30px 0 50px;
        font-size: 1.5em;
    }

    .btn {
        margin-bottom: 50px;
    }
}