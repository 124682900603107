:root {
    /* Colors */
    --c-red:#db241f;
    --c-gray:#3a3b3a;
    --c-lightgray:#dbdbdb;
    --c-whitesmoke:#fafafa;
    --c-white:#fff;
    --c-black:#000;
    /* Values */
    --padding-size:25px;
    --cols-count:2;
    /* Shadows */
    --shadow-black:0 5px 27px 0 rgba(225, 225, 225, 0.58);
    --shadow-red:0 5px 27px 0 rgba(219, 36, 31, 0.58);
    --shadow-red-hover:0 2px 22px 0 rgba(219, 36, 31, 0.48);
    @media (min-width: 768px) {
        --padding-size:50px;
        --cols-count: 4;
    }
    @media (min-width: 990px) {
        --col-size:240px;
        --cols-count: 6;
    }
}