
/* =================================================== */
/*                     Related Block                   */
/* =================================================== */

.related-block {
    margin-bottom: 80px;

    &__item {
        a {
            text-decoration: underline;
            color: inherit;
        }
        &_content {
            h4 {
                font-size: 1.3em;
                font-family: "Montserrat";
                font-weight: 500;
            }
        }
    }

    @media (min-width: 990px) {
        margin-bottom: 180px;

        &__item {
            &_content {
                padding: 20px 0;
                transition:
                    .31s cubic-bezier(0.43, 0.29, 0.34, 1.03),
                    background .15s ease-out .21s,
                    box-shadow .15s ease-out .21s;

                h4 {
                    font-size: 1.6em;
                }
            }

            &:hover {
                .related-block__item_content {
                    background: var(--c-whitesmoke);
                    box-shadow: var(--shadow-black);
                    padding-left: 50px;
                }
            }
        }

        &__items {
            &:hover {
                .related-block__item:not(:hover) {
                    opacity: 0.5;
                }  
            }
        }
    }
}