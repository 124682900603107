/***** Lines Background *****/

.lines-background {
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    position: fixed;
    display: flex;
}
.relative .lines-background {
    position: absolute;
}

.line {
    position: relative;
    flex:1;
    height: 0;
    &:before {
        content:'';
        position: absolute;
        top:0;
        left:0;
        width: 1px;
        height: 100%;
        background: rgba(0,0,0,.1);
    }

    &-3, &-4, &-5, &-6 {
        display: none;
    }

    &-1 {
        transition: height .98s ease-out .23s;
        @media (min-width: 990px) {
            max-width: var(--col-size);
        }
    }
    &-2 {
        transition: height .85s ease-out .12s;
        @media (min-width: 990px) {
            max-width: var(--col-size);
        }
    }
    &-3 {
        transition: height .88s ease-out .14s;
        @media (min-width: 768px) {
            display: block;
        }
        @media (min-width: 990px) {
            max-width: var(--col-size);
        }
    }
    &-4 {
        transition: height .98s ease-out .23s;
        @media (min-width: 768px) {
            display: block;
        }
        @media (min-width: 990px) {
            max-width: var(--col-size);
        }
    }
    &-5 {
        transition: height .72s ease-out .26s;
        @media (min-width: 990px) {
            max-width: var(--col-size);
            display: block;
        }
    }
    &-6 {
        transition: height .80s ease-out .19s;
        @media (min-width: 990px) {
            max-width: var(--col-size);
            display: block;
        }
    }

    &-padding {
        transition: height .92s ease-out .27s;
        max-width: var(--padding-size);
        @media (min-width: 768px) {
            min-width: 25px;
            max-width: var(--padding-size);
        }
        @media (min-width: 990px) {
            min-width: var(--padding-size);
            max-width: none;
            flex: 0.4;
        }
    }
}

/***** Lines Background [end] *****/