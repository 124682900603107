/* ============================================ */
/*                    Cases                     */
/* ============================================ */

.cases {
    margin-bottom: 40px;
    
    &__item {
        margin-bottom: 60px;
        &_label {
            font-family: "Montserrat", sans-serif;
            font-weight: 700;
            color:var(--c-red);
            line-height: 1em;
            margin-bottom: 15px;
        }
        &_img {
            display: none;
        }

        h3 {
            font-family: "Montserrat", sans-serif;
            font-weight: 600;
            margin:0 0 30px 0;
        }
        p {
            margin:0 0 30px 0;
        }
    }

    &__controls {
        justify-content: space-between;
        &_number {
            font-family: "Montserrat", sans-serif;
            font-weight: 700;
            color:var(--c-red);

            color:var(--c-lightgray);
            border-top: 5px solid transparent;
            padding: 4px 2px 4px 2px;
           
            &--active {
                color:var(--c-red);
                border-top-color:var(--c-red);
            }
        }  
    }

    @media (min-width: 768px) {
        &__controls {
            flex:1;
            cursor:pointer;
            &:not(.cases__controls_number--active) {
                border-top-color: var(--c-lightgray);
            }
        }
    }
    @media (min-width: 990px) {
        min-height: 100vh;
        &__item {
            position: relative;
            margin-bottom: 100px;
            &_img {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                z-index: -1;
                width: calc((100% / var(--cols-count)) * 2.5);
                & > img {
                    width: 100%;
                }
            }

            h3 {
                font-size: 1.4em;
            }
        }
    }
}