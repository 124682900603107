.tech-directions {
    @media (min-width: 990px) {
        margin-bottom: 200px;

        .short-overview {
            &:nth-child(even) {
                flex-direction: row-reverse;
            }
        }
    }
}