/* ============================================ */
/*                     Home                     */
/* ============================================ */

.home {
    min-height: calc(100vh - 200px);
    
    h1 {
        color:var(--c-black);
        overflow: hidden;
        @media (min-width: 990px) {
            font-size: 5.5em;
            min-width: 690px;
        }
    }

    .btn--primary {
        @media (min-width: 990px) {
            margin-bottom: 100px;
        }
    }
}