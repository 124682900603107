/* ============================================ */
/*                    We Do                     */
/* ============================================ */

.we-do {
    @media (min-width: 990px) {
        min-height: 100vh;
    }
    &__items {
        @media (min-width: 990px) {
            &:hover {
                .we-do__item {
                    &:not(:hover) {
                        opacity: 0.5;
                    }
                }
            } 
        }
    }
    &__btn-row {
        @media (min-width: 990px) {
            justify-content: flex-end;
            padding-right: 50px;
            transition:.31s cubic-bezier(0.43, 0.29, 0.34, 1.03);
            transform: translateX(-50px);
            opacity: 0;
        }
    }
}

.we-do__item {
    margin-bottom: 50px;
    color: var(--c-gray);
    text-decoration: none;

    &_number {
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        font-size: 1.1em;
        line-height: 1em;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        margin-bottom: 30px;
    }
    &_background {
        padding: 40px 0;
    }

    h3 {
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        font-size: 1.1em;
        margin-bottom: 45px;
        margin-top: 0;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            font-family: "Montserrat", sans-serif;
            font-weight: 500;
            margin-bottom: 30px;
            font-size: 1.05em;
            line-height: 1.6em;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .btn {
        margin-top: 40px;
    }

    @media (min-width: 990px) {
        transition: .15s ease-out;

        &_background {
            padding: 50px 0;
            transition:
                .31s cubic-bezier(0.43, 0.29, 0.34, 1.03),
                background .15s ease-out .21s,
                box-shadow .15s ease-out .21s;
        }

        &--active {
            .we-do__item_background {
                padding-left: 50px;
            }
        }

        &:hover {
            .we-do__item_background {
                background: var(--c-whitesmoke);
                box-shadow: var(--shadow-black);
                padding-left: 50px;
            }
            .we-do__btn-row {
                transform: translateX(0);
                opacity: 1;
            }
        }

        h3 {
            font-size: 1.5em;
        }
    
    }
}
