/* ============================================ */
/*                    Reviews                   */
/* ============================================ */

.reviews {
    margin-bottom: 80px;
    &__item {
        margin-bottom: 80px;
        position: relative;

        &_photo {
            margin-bottom: 30px;
            img {
                width: 50%;
            }
        }

        &_text {
            margin-top: 0;
            margin-bottom: 30px;
        }

        &_author {
            font-family: "Montserrat", sans-serif;
            font-weight: 600;
            margin: 0;
        }
    }
}

.quote {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 6em;
    color: var(--c-lightgray);
    /***/
    margin: 0;
    line-height: 0.8em;
    position: absolute;
    height: 34px;
    top: 36px;
    left: 90px;
    /***/
    @media (min-width: 768px) {
        position: relative;
        top: auto;
        left: auto;
        margin-bottom: 30px;
    }
}