* {
    box-sizing: border-box;
}

h1, h2 {
    color:var(--c-gray);
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 2.4em;
    margin-top: 40px;
    margin-bottom: 40px;
}
h3 {
    font-size: 24px;
}
html,
body {
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 100%;
    color:var(--c-gray);
    overflow-x: hidden;
    min-width: 320px;
    -webkit-text-size-adjust: 100%;
}
button,
input,
textarea,
select {
    -webkit-appearance: none;
}

.fixed-bottom, .fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
}
.fixed-bottom {
    bottom: 0;
}
.relative {
    position: relative;
}
.text-center {
    text-align: center;
}
.w-100 {
    width: 100%;
}
.h-100 {
    height: 100%;
}
.d-flex {
    display: flex;
}
.mb {
    &-1 {
        margin-bottom: 0.5rem;
       }
    &-2 {
        margin-bottom: 1rem;
    }
}
.my-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}
.px-2 {
    padding-left: 1rem;
    padding-right: 1rem;
}
.d-none {
    display: none;
}
.font-weight-bold {
    font-weight: 600;
}

.link-red {
    font-size: 1.65rem;
    color: var(--c-red);
    margin-left: 1rem;
    white-space: nowrap;
}

.preload-screen {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 100;
    top:0;
    left:0;
    background: #fff;
    transition: .08s ease-out;
    pointer-events: none;

    .logo {
        flex:1;
        position: relative;
        top:auto;
        left: auto;
    }
}

.wrapper-background {
    background:#fff;
    overflow: hidden;

    &.loaded {
        transition: .75s ease-out .05s;
        background: var(--c-whitesmoke);
        overflow: visible;
        .line {
            height: 100%;
        }
        .preload-screen {
            opacity: 0;
        }
    }
}




.wrapper {
    margin: 0 auto;
    width: calc(100% - (var(--padding-size) * 2));
    z-index: 1;
    position: relative;
    @media (min-width: 990px) {
        display: flex;
        width: 100%;
        &:before,
        &:after {
            content:'';
            position: relative;
            min-width: var(--padding-size);
            max-width: none;
            flex: 0.4;
        }
    }
}

.content {
    @media (min-width: 990px) {
        flex:6;
        max-width: calc(var(--cols-count) * var(--col-size));
    }
}

.content-block {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-right: -1px; /** For covering lines **/
}



header {
    height: 60px;
    padding-top: 15px;
    @media (min-width: 990px) {
        padding-top: 50px;
        height: 100px;
        margin-bottom: 100px;
    }
}

.logo {
    height: 30px;
    @media (min-width: 990px) {
        position: absolute;
        height: 50px;
        z-index: 2;
    }
    @media (min-width: 1740px) {
        position: fixed;
        top:50px;
        left:50px;
    }
}

h1 {
    & > span {
        color:var(--c-red);
    }
    & > .swiper-area{
        display: block;
        overflow: hidden;
        height: 50px;
        width: 100%;
        max-width: 550px;
        position: relative;
    }
    @media (min-width: 990px) {
        font-size: 3.8em;
        margin-bottom: 40px;
        & > .swiper-area{
            height: 110px;
        }
    }
}

h2 {
    @media (min-width: 990px) {
        font-size: 3.8em;
        margin-bottom: 40px;
    }
}

.img {
    &-fluid {
        max-width: 100%;
    }
    &-90 {
        width: 90%;
        max-width: 90%;
    }
}

.text {
    &-left {
        text-align: left;
    }
    &-right {
        text-align: right;
    }
    &-center {
        text-align: center;
    }

    &-uppercase {
        text-transform: uppercase;
    }
}

.lead {
    font-size: 18px;
    font-weight: 100;
}

.row {
    display: flex;
    flex-direction: row;
    margin-left: -15px;
    margin-right: -15px;
}