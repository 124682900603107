/* ============================================ */
/*                Navigation                    */
/* ============================================ */

.nav {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 40px var(--padding-size) 0;
    overflow: hidden;
    z-index: 20;
    pointer-events: none;

    .lines-background {
        z-index: -1;
        position: absolute;
        .line {
            &:before {
                opacity: 0;
                transition: .5s ease-out .10s;
                z-index: 1;
            }
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 100%;
                background: var(--c-whitesmoke);
                transition: .25s ease-out .15s;
            }
        }
    }

    footer {
        opacity: 0;
        transition: .12s ease-out .32s;
    }
    a {
        color: var(--c-gray);
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        font-size: 1.8em;
        line-height: 1.6em;
        text-decoration: none;
    }

    &-item {
        margin-bottom: 20px;
        display: block;
        overflow: hidden;

        a {
            display: inline-block;
            transform: translateX(-100%);
            transition: transform .21s ease-out .32s;
        }
        .external-link {
            color: var(--c-red);
            text-decoration: underline;
        }
    }

    &.shown {
        width: 100%;
        height: 100%;
        padding: 40px var(--padding-size) 0;
        pointer-events: all;

        .lines-background .line {
            &:before {
                opacity: 1;
            }
            &:after {
                width: 100%;
            }
        }
        footer {
            opacity: 1;
        }
        .nav-item a {
            transform: translateX(0);
        }
    }
    @media (min-width: 990px) {
        display: flex;
        position: relative;
        padding: 0;
        height: auto;
        width: auto;
        background: none;

        footer {
            display: none;
        }

        &-item {
            flex:1;
        }

        a {
            color: var(--c-gray);
            font-family: "Montserrat", sans-serif;
            font-weight: 500;
            font-size: 1.05em;
            line-height: 1.6em;
            text-decoration: none;
            cursor: pointer;
            transition: transform .48s ease-out, color .12s ease-out;
            &:hover {
                color: var(--c-red);
            }
        }

        .lines-background {
            display: none;
        }

        &.shown {
            width: auto;
            height: auto;
            padding: 0;
        }
    }    
}



.btn-nav {
    width: 24px;
    height: 30px;
    background: none;
    border: none;
    display: flex;
    justify-content: space-between;
    position: fixed;
    right: 37px;
    padding: 0;
    outline:none;
    z-index: 25;

    &__line {
        height: 28px;
        width: 1px;
        background: var(--c-gray);
        transition: transform .12s ease-out;
    }

    &.active {
        .btn-nav__line {
            &:nth-child(1) {
                transform: translateX(12px) rotate(45deg);
            }
            &:nth-child(2) {
                opacity: 0;
                transform: rotate(45deg);
            }
            &:nth-child(3) {
                transform: translateX(-11px) rotate(-45deg);
            }
        }
    }
    @media (min-width: 990px) {
        display: none;
    }
}