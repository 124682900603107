$gray: #828282;
$img-box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.3);
$color-box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);

.text-muted {
    color: $gray;
}

.list_item {
    color: var(--c-red);
}

.parg_item {
    padding-top: 5px;
    padding-bottom: 5px;
}

.portfolio_img {
    max-width: 100%;
    height: auto;
    margin-left: 0px;
    margin-top: 30px;
    // box-shadow: $img-box-shadow;
}

.portfolio_img2 {
    max-width: 100%;
}


.section_header {
    font-size: 50px;
    @media screen and (max-width: 990px) {
        font-size: 28px;
    }
}

.section_wraper {
    @extend .row;
    justify-content: space-between;
    & > div {
        padding: 1rem;
        img {
            box-shadow: $img-box-shadow;
        }
    }

    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}

.fedback_img {
    max-width: 100px;
    max-height: 100px;
}

@media screen and (max-width: 425px) {
    // .section_wraper {
    //     flex-wrap: wrap;
    // }

    .section_header {
        font-size: 1.4em;
    }
}




// portfolio slider

.portfolio_list {
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style-type: none;
    font-weight: 700;
    font-size: 26px;
    color: var(--c-red);
    padding-left: 0;
    height: 50px;
    margin-bottom: 2.2rem;
    .list-item {
        margin-right: 71px;
        text-decoration: underline;
        cursor: pointer;
        transform-origin: 0 50%;
        transition: all 0.25s ease-in;
        &:hover {
            transform: scale(1.3);
            text-decoration: none;
            padding: 0;
        }

        @media screen and (max-width: 990px) {
            margin-right: 2rem;
        }
    }
}

.swiper-container {
    width: 87vw;
    height: auto;
    margin-bottom: 2rem;
}

.swiper__slide {
    a {
        text-decoration: none;
        color: inherit;
    }
    img {
        width: 100%;
    }
    .slide {
        &_header {
            font-size: 32px;
            margin-top: 0.75rem;
            margin-bottom: 0.75rem;
        }
        &_subheader {
            color: var(--c-lightgray);
            margin-top: 0.75rem;
            margin-bottom: 0.75rem;
        }
        &_parg {
            line-height: 1.5rem;
            display: none;
        }

        &_wrapper {
            width: calc(100% - 4rem);
            margin: 0 auto;
            transition: 0.28s cubic-bezier(0.35, 0.42, 0.58, 1);

            @media screen and (min-width: 768px) {
                &:hover {
                    width: 100%;
                    & .slide_parg {
                        display: block;
                        transition: .5s; 
                    }
                }
            }
            
        }
    }
}


//portfolio related
.related-section {
    margin-bottom: 3rem;
    margin-top: 4rem;
    @media screen and (max-width: 990px) {
       .content-block {
           justify-content: center;
       }
    }
}
.related-wrapper {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    margin-bottom: 2rem;
    @media screen and (min-width: 600px) and (max-width: 990px) {
        max-width: 70%;
    }
}
.related_link {
    text-decoration: none;
    color: inherit;
}
.related_img_wrapper {
    overflow: hidden;
    margin-left: 1px;


}
.related_img {
    transition: 0.28s cubic-bezier(0.35, 0.42, 0.58, 1);
    width: 100%;
    &:hover {
        transform: scale(1.5);
    }
}

.related_header {
    font-size: 26px;
    margin-top: 0.75rem;
    margin-bottom: 0;
}

.related_subheader {
    color: var(--c-lightgray);
    margin-top: 0.75rem;
}

.colors-wrapper {
    @extend .row;
    justify-content: start;
    flex-wrap: wrap;
    & > div {
        padding: 1rem;
        text-align: center;
    }
}
.color-item {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    box-shadow: $color-box-shadow;
    border: 5px solid white;
    margin: 0 auto;
    & + p {
        margin-top: 0.5rem;
    }
}

.fonts-wrapper {
    h3 {
        margin: 0.75rem 0;
    }
}

.link-wrapper {
    justify-content: flex-end;
    & > div {
        padding-right: 0.5rem;
    }
    @media screen and (max-width: 768px) {
        & > div {
            margin-left: 50%;
        }
    }
    @media screen and (max-width: 443px) {
        & > div {
            margin-left: 0%;
        }
    }
}

.tag-wrapper {
    @extend .font-weight-bold;
    font-size: 24px;
    display: inline-block;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    span:first-child {
        color:  var(--c-red);
    }
}