/***** Columns *****/

.clxs {
    &-1 {
        flex:1;
        flex-basis: calc((100% / var(--cols-count)) * 1);
        max-width: calc((100% / var(--cols-count)) * 1);
    }
    &-2 {
        flex:2;
        flex-basis: calc((100% / var(--cols-count)) * 2);
        max-width: calc((100% / var(--cols-count)) * 2);
    }
}

.cl-full {
    flex: var(--cols-count);
    flex-basis: 100%;
}

.offset {
    &--xs {
        &-1-right {
            @media (min-width: 300px) and (max-width: 767px) {
                margin-right: 50%;
            }
        }
        &-1-left {
            @media (min-width: 300px) and (max-width: 767px) {
                margin-left: 50%;
            }
        }
    }
    @media (min-width: 768px) and (max-width: 989px) {
        &--md {
            &-1-left {
                margin-left: 25%;
            }
            &-2-left {
                margin-left: 50%;
            }
            &-3-left {
                margin-left: 75%;
            }
            &-1-right {
                margin-left: 25%;
            }
            &-2-right {
                margin-right: 50%;
            }
            &-3-right {
                margin-right: 75%;
            }
        }
        &-shift-md-top {
            margin-left: 25%;
            margin-top: -25%;
            margin-bottom: 25%;
        }
    }
    @media (min-width: 990px) {
        &--lg {
            &-1-right {
                margin-right: calc((100% / var(--cols-count)) * 1);
            }
            &-2-right {
                margin-right: calc((100% / var(--cols-count)) * 2);
            }
            &-3-right {
                margin-right: calc((100% / var(--cols-count)) * 3);
            }
            &-4-right {
                margin-right: calc((100% / var(--cols-count)) * 4);
            }
            &-5-right {
                margin-right: calc((100% / var(--cols-count)) * 5);
            }
            &-6-right {
                margin-right: calc((100% / var(--cols-count)) * 6);
            }
            &-1-left {
                margin-left: calc((100% / var(--cols-count)) * 1);
            }
            &-2-left {
                margin-left: calc((100% / var(--cols-count)) * 2);
            }
            &-3-left {
                margin-left: calc((100% / var(--cols-count)) * 3);
            }
            &-4-left {
                margin-left: calc((100% / var(--cols-count)) * 4);
            }
            &-5-left {
                margin-left: calc((100% / var(--cols-count)) * 5);
            }
            &-6-left {
                margin-left: calc((100% / var(--cols-count)) * 6);
            }
        }
        &-shift-lg-bottom {
            margin-left: 0;
            margin-top: calc((100% / var(--cols-count)) * 1);
        }
        &-shift-lg-top {
            margin-top: 0;
            margin-right: 0;
            margin-left: 0;
            margin-bottom: calc((100% / var(--cols-count)) * 1);
        }
    }
}

.clmd {
    @media (min-width: 768px) {
        &-1 {
            flex:1;
            flex-basis: calc((100% / var(--cols-count)) * 1);
            max-width: calc((100% / var(--cols-count)) * 1);
        }
        &-2 {
            flex:2;
            flex-basis: calc((100% / var(--cols-count)) * 2);
            max-width: calc((100% / var(--cols-count)) * 2);
        }
        &-3 {
            flex:3;
            flex-basis: calc((100% / var(--cols-count)) * 3);
            max-width: calc((100% / var(--cols-count)) * 3);
        }
        &-4 {
            flex:4;
            flex-basis: calc((100% / var(--cols-count)) * 4);
            max-width: calc((100% / var(--cols-count)) * 4);
        }
    }
}

.cllg {
    @media (min-width: 990px) {
        &-1 {
            flex:1;
            flex-basis: calc((100% / var(--cols-count)) * 1);
            max-width: calc((100% / var(--cols-count)) * 1);
        }
        &-2 {
            flex:2;
            flex-basis: calc((100% / var(--cols-count)) * 2);
            max-width: calc((100% / var(--cols-count)) * 2);
        }
        &-3 {
            flex:3;
            flex-basis: calc((100% / var(--cols-count)) * 3);
            max-width: calc((100% / var(--cols-count)) * 3);
        }
        &-4 {
            flex:4;
            flex-basis: calc((100% / var(--cols-count)) * 4);
            max-width: calc((100% / var(--cols-count)) * 4);
        }
        &-5 {
            flex:5;
            flex-basis: calc((100% / var(--cols-count)) * 5);
            max-width: calc((100% / var(--cols-count)) * 5);
        }
        &-6 {
            flex:6;
            flex-basis: calc((100% / var(--cols-count)) * 6);
            max-width: calc((100% / var(--cols-count)) * 6);
        }
    }
}

.clxl {
    @media (min-width: 1440px) {
        &-1 {
            flex:1;
            flex-basis: calc((100% / var(--cols-count)) * 1);
            max-width: calc((100% / var(--cols-count)) * 1);
        }
    
        &-2 {
            flex:2;
            flex-basis: calc((100% / var(--cols-count)) * 2);
            max-width: calc((100% / var(--cols-count)) * 2);
        }
    
        &-3 {
            flex:3;
            flex-basis: calc((100% / var(--cols-count)) * 3);
            max-width: calc((100% / var(--cols-count)) * 3);
        }
    
        &-4 {
            flex:4;
            flex-basis: calc((100% / var(--cols-count)) * 4);
            max-width: calc((100% / var(--cols-count)) * 4);
        }
    }
}

/***** Columns [end] *****/