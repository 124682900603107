/* ============================================ */
/*                Get in Touch                  */
/* ============================================ */

.contact {
    min-height: 100vh;
    &-form {
        margin-bottom: 60px;
        &__success {
            font-family: "Montserrat", sans-serif;
            font-weight: 600;
            font-size: 1.1em;
            margin-bottom: 50px;
        }        
    }
}

.input-standard,
.input-area__label {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 2em;
    color: var(--c-red);
    @media (min-width: 990px) {
        font-size: 2.5em;
    }
}

.input-area {
    position: relative;
    margin-bottom: 35px;
    
    &__input {
        height: 40px;
        @media (min-width: 990px) {
            height: 48px;
        }
    }
    
    &__textarea {
        font-size: 1.5em;
        height: 4.8em;
        resize: none;
        &__bigger {
            height: 180px;
            max-height: 180px;
            min-height: 180px;
        }
        @media (min-width: 372px) {
            height: 3.2em;
        }
        @media (min-width: 619px) {
            height: 1.5em;
        }
        @media (min-width: 990px) {
            height: 2.4em;
        }
        @media (min-width: 1583px) {
            height: 1.22em;
        }
    }
    &__label {
        position: absolute;
        height: 100%;
        width: 100%;
        top:0;
        left: 0;
        z-index: -1;
        & > sup {
            font-size: 0.5em;
        }
    }

    &__file {
        color: var(--c-red);
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        font-size: 1.1em;
        padding: 3px 2px;
        border-bottom: 3px solid var(--c-red);
        display: inline-block;
        text-align: center;
        cursor: pointer;
        & > input {
            display: none;
        }
    }

    &--file-area {
        text-align: center;
        margin-bottom: 40px;
    }
}

.input-standard {
    width: 100%;
    border: none;
    background: none;
    outline: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    z-index: 1;
    &::-ms-clear {
        display: none;
    }
    &:placeholder-shown + .input-area__label {
        display: block;
        transition: .12s ease-out;
    }
}

::placeholder {
    color:transparent;
}

:-moz-placeholder {
    color:transparent;
}

:-ms-input-placeholder {
    color:transparent;
}

::-webkit-input-placeholder {
    color:transparent;
}
